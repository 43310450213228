<!-- /// HERO SECTION /// -->
<div id="hero-section" class="medium-margin">
    <div class="row hero-unit">
        <div class="container">
            <div class="hero-caption"><!-- Main Tagline -->
                <h1>Games made by <span class="colored">one man</span></h1>
            </div>
        </div>
    </div>
</div><!-- Hero Section End -->
