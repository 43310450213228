import { Component, OnInit } from '@angular/core';
import { Game } from '../common/models/game';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.scss']
})
export class GamesComponent implements OnInit {

  games: Game[] = []; 


  constructor(private db: AngularFirestore) { }

  ngOnInit() {
    console.log("games init")
    this.db.collection('games').get().subscribe( r => {
      if(r && r.docs){
        r.docs.forEach( i => {
          let data = i.data();
          this.games.push({
            name : data.name,
            imageUrl : data.imageUrl,
            appStoreLink : data.appStoreLink,
            playStoreLink : data.playStoreLink,
            description : data.description,
            platform : data.platform,
            type : data.type 
          });
        })
      }
    })
  }

}
