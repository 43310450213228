<!-- /// FOOTER /// -->
<footer id="main-footer">
    <div id="footer"> 
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <p id="copyright">&copy; Portomagi <span id="year">{{ year }}</span></p><!-- Copyright Text -->
                    <ul class="social-links"> <!-- Social Media Icons -->
                        <li><a [routerLink]="['/terms']">Terms & Conditions</a></li>
                        <li><a [routerLink]="['/privacy']" routerLinkActive="router-link-active" >Privacy</a></li>
                        <li><a href="mailto: info@portomagi.com"><i class="fa fa-envelope fa-lg icon-social"></i> <span class="d-none d-md-inline"> info@portomagi.com</span></a></li>
                    </ul>
                </div>
            </div>
        </div><!-- Container End -->
    </div>
</footer><!-- Footer End -->
