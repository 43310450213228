  <!-- /// ABOUT SECTION /// -->
  <div id="about" class="large-margin">
      <a href="about"></a><!-- Nav Anchor -->
      <div class="row heading tiny-margin">
          <div class="col-md-auto">
              <h1 class="animation-element slide-down">ABOUT <span class="colored">US</span></h1>
          </div>
          <div class="col">
              <hr class="animation-element extend">
          </div>
      </div>
      <div class="row">
          <div class="col-md-6">
              <p class="small-margin">We are a dedicated team of developers, designers, artists, programmers, and most importantly gaming enthusiasts. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vitae ante pharetra, molestie nunc non, interdum ipsum.</p>
              <img id="awards" src="../../assets/images/awards.png" class="img-fluid" alt="awads">
          </div>
          <div class="col-md-6">
              <img id="support-image" src="../../assets/images/placeholder.jpg" data-src="images/about.jpg" class="img-fluid b-lazy" alt="digital collage">
          </div>
      </div>
  </div>
