<div *ngIf="games && games.length > 0" id="games" class="large-margin">
  <a href="games"></a><!-- Nav Anchor -->
  <div class="row heading tiny-margin">
      <div class="col-md-auto">
          <h1 class="animation-element slide-down">OUR <span class="colored">GAMES</span></h1>
      </div>
      <div class="col">
          <hr class="animation-element extend">
      </div>
  </div>
  <div class="row ">
      <div class="col-md-11 small-margin">
         <p>I design games for mobile platforms. I like animals therefore I try to incorporate them in all of my games in clever ways. It is hard to create an original game from scratch as one person but I try to do my best to make them as polished as posible.</p>
      </div>
  </div>
  <div class="games-portfolio ">      
      <!-- Game Card Video -->
      <div *ngFor="let game of games" class="row game-card mobile new">
          <div class="col-lg-12 col-xl-5 game-card-left">
              <img [src]="game.imageUrl" class="img-fluid b-lazy" alt="video thumbnail"> <!-- Video Thumbnail Img -->
          </div>
          <div class="col-lg-12 col-xl-7 game-card-right">
              <h2 class="short-hr-left">{{ game.name }}</h2>
              <p class="tags"><span class="subtle">{{ game.type }} | {{ game.platform }}</span> </p>
              <p class="game-description">{{ game.description }}</p>
              <a [href]="game.playStoreLink" target="_blank" class="button-store">
                  <i class="fa fa-android fa-2x"></i>
                  <p>Available on<br><span class="strong">Google Play</span></p>
              </a>
              <a [href]="game.appStoreLink" target="_blank" class="button-store">
                  <i class="fa fa-apple fa-2x"></i>
                  <p>Available on<br><span class="strong">Apple Store</span></p>
             </a>
          </div>
      </div><!-- Game Card End -->
  </div>
</div>
