<div class="container">
  <h2>Privacy Policy</h2>
  <p>This page informs you of our policies regarding the collection, use, and
    disclosure of personal data when you use our applications and the choices you have
    associated with that data.
  </p>
  <p>If you choose to use our applications, then you agree to the collection and use of information in
    relation to this policy. The Personal Information that collected is used for providing and improving
    the Service. I will not use or share your information with anyone except as described
    in this Privacy Policy.
  </p>
  <p>Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in
    our Terms and Conditions.</p>

  <p><strong>Definitions</strong></p>

  <p>Service</p>
  <p>Service is the any of the applications operated by Portomagi</p>

  <p>Personal Data</p>
  <p>Personal Data means data about a living individual who can be identified
    from those data (or from those and other information either in our
    possession or likely to come into our possession).</p>

  <p>Usage Data</p>
  <p>Usage Data is data collected automatically either generated by the use of
    the Service or from the Service infrastructure itself (for example, the
    duration of a page visit).</p>

  <p>Cookies</p>
  <p>Cookies are small files stored on your device (computer or mobile device).</p>

  <p><strong>Information Collection and Use</strong></p>
  <p>We collect several different types of information for various purposes to
    provide and improve our Service to you.
  </p>

  <p><strong>Types of Data Collected</strong></p>

  <p>Personal Data</p>
  <p>While using our Service, we may ask you to provide us with certain personally
    identifiable information that can be used to contact or identify you
    ("Personal Data"). Personally identifiable information may include, but is not
    limited to:

  </p>
  <ul>
    <li>Cookies and Usage Data</li>
  </ul>

  <p>Usage Data</p>
  <p>When you access the Service by or through a mobile device, we may collect
    certain information automatically, including, but not limited to, the type of
    mobile device you use, your mobile device unique ID, the IP address of your
    mobile device, your mobile operating system, the type of mobile Internet
    browser you use, unique device identifiers and other diagnostic data ("Usage
    Data").</p>

  <p>Cookies</p>
  <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers.
    These are sent to your browser from the websites that you visit and are stored on your device's internal
    memory.
  </p>
  <p>This Service does not use these “cookies” explicitly. However, the app may use third party code and
    libraries that use “cookies” to collect information and improve their services. You have the option to
    either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose
    to refuse our cookies, you may not be able to use some portions of this Service.
  </p>

  <p><strong>Use of Data</strong></p>
  <ul>
    <li>To provide and maintain the Service</li>
    <li>To notify you about changes to our Service</li>
    <li>To allow you to participate in interactive features of our Service when
      you choose to do so</li>
    <li>To provide customer care and support</li>
    <li>To provide analysis or valuable information so that we can improve the
      Service</li>
    <li>To monitor the usage of the Service</li>
    <li>To detect, prevent and address technical issues</li>
  </ul>

  <p><strong>Transfer of Data</strong></p>

  <p>Your information, including Personal Data, may be transferred to - and
    maintained on - computers located outside of your state, province, country or
    other governmental jurisdiction where the data protection laws may differ than
    those from your jurisdiction.</p>

  <p>If you are located outside Turkey and choose to provide information to us,
    please note that we transfer the data, including Personal Data, to Turkey and
    process it there.</p>

  <p>Your consent to this Privacy Policy followed by your submission of such
    information represents your agreement to that transfer.</p>
  <p>Portomagi will take all steps reasonably necessary to ensure that your data
    is treated securely and in accordance with this Privacy Policy and no transfer
    of your Personal Data will take place to an organization or a country unless
    there are adequate controls in place including the security of your data and
    other personal information.</p>

  <p><strong>Disclosure Of Data </strong></p>
  <p>Legal Requirements</p>
  <p>Portomagi may disclose your Personal Data in the good faith belief that
    such action is necessary to:</p>
  <ul>
    <li>To comply with a legal obligation
    </li>
    <li>To protect and defend the rights or property of Portomagi</li>
    <li>
      To prevent or investigate possible wrongdoing in connection with the
      Service
    </li>
    <li>To protect the personal safety of users of the Service or the public</li>
    <li>To protect against legal liability</li>
  </ul>

  <p>As an European citizen, under GDPR, you have certain individual rights. You
    can learn more about these guides in the <a href="https://termsfeed.com/blog/gdpr/#Individual_Rights_Under_the_GDPR"
      target="_blank">GDPR Guide</a>.</p>

  <p><strong>Security of Data </strong></p>
  <p>The security of your data is important to us but remember that no method of
    transmission over the Internet or method of electronic storage is 100% secure.
    While we strive to use commercially acceptable means to protect your Personal
    Data, we cannot guarantee its absolute security.</p>

  <p><strong>Service Providers</strong></p>
  <p>We may employ third party companies and individuals to facilitate our Service
    ("Service Providers"), to provide the Service on our behalf, to perform
    Service-related services or to assist us in analyzing how our Service is used.

    These third parties have access to your Personal Data only to perform these
    tasks on our behalf and are obligated not to disclose or use it for any other
    purpose.</p>

  <p>Link to privacy policy of third party service providers used by the app</p>
  <ul>
    <!---->
    <li><a href="https://support.google.com/admob/answer/6128543?hl=en" target="_blank">AdMob</a>
    </li>
    <li>
      <p>Unity Analytics</p>
      <p>Unity Analytics is provided by Unity Technologies.</p>
      <p><a href="https://unity3d.com/legal/privacy-policy](https://unity3d.com/legal/privacy-policy" target="_blank">For more information on what type of information Unity Analytics collects,
          please visit their Privacy Policy page</a></p>
    </li>
  </ul>


<p><strong>Links to Other Sites</strong></p>

<p>Our Service may contain links to other sites that are not operated by us. If
  you click a third party link, you will be directed to that third party's site.
  We strongly advise you to review the Privacy Policy of every site you visit.
</p>
<p>We have no control over and assume no responsibility for the content, privacy
  policies or practices of any third party sites or services.</p>

<p><strong>Children’s Privacy</strong></p>
<p>These Services do not address anyone under the age of 13. We do not knowingly collect
  personally identifiable information from children under 13. In the case we discover that a child
  under 13 has provided me with personal information, we immediately delete this from
  our servers. If you are a parent or guardian and you are aware that your child has provided us with personal
  information, please contact us so that I will be able to do necessary actions.
</p>
<p><strong>Changes to This Privacy Policy</strong></p>
<p>We may update our Privacy Policy from time to time. Thus, you are advised to review
  this page periodically for any changes. We will notify you of any changes by posting
  the new Privacy Policy on this page. These changes are effective immediately after they are posted on
  this page.
</p>
<p><strong>Contact Us</strong></p>
<p>If you have any questions about this Privacy Policy, please contact us:</p>
<ul>
  <li>info@portomagi.com</li>
</ul>
</div>