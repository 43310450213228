import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  scrolled: boolean = false;
  constructor() { 
  }

  ngOnInit() {
  }

  @HostListener("window:scroll", [])
  onscroll(){
    this.scrolled = window.scrollY > 50;
    // if(this.router.url === "" ) {
    //   this.scrolled = window.scrollY > window.innerHeight;
    // }else{
    // }   
  }

}
