    <!-- //// HEADER //// -->
    <header id="main-header">
      <nav class="navbar fixed-top navbar-expand-lg navbar-dark" [ngClass]="{'scrolled': scrolled}">
          <div class="container">
              <!-- Site Logo -->
              <a [routerLink]="['/']" routerLinkActive="router-link-active"  id="logo" class="navbar-brand"><img src="../../assets/images/portomagi_logo_web.png" width="300" /></a>
              <!-- Dropdown Button -->
          </div>
      </nav>
  </header><!-- Header End -->
